(function () {
    angular.module('informaApp')
        .service('OmnitureSvc', OmnitureSvc);

    function OmnitureSvc(OmnitureDictionaryHelper, AuthenticationService) {
        const context = {
            omnitureDictionaryHelper: OmnitureDictionaryHelper
        };

        return {
            trackLogin() {
                trackLogInOut(
                    AuthenticationService,
                    OmnitureDictionaryHelper.events.account.login,
                    OmnitureDictionaryHelper.pages.login,
                    OmnitureDictionaryHelper
                );
            },
            trackLogout() {
                trackLogInOut(
                    AuthenticationService,
                    OmnitureDictionaryHelper.events.account.logout,
                    OmnitureDictionaryHelper.pages.main,
                    OmnitureDictionaryHelper
                );
            },
            trackPassForget() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.resetPassword;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.account.forgottenPassword;

                s.t();
            },
            trackFilter(filterName, filterValues) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.filter.filterName] = filterName + ' - ' + filterValues;
                s.events = OmnitureDictionaryHelper.events.filter.filterCount;

                s.t();
            },
            trackGenerate(filter) {
                var separator = ', ';

                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                var names = ['startDate', 'endDate', 'diseases', 'subDiseases', 'indications', 'companies', 'drugclasses', 'molecules', 'targets', 'drugs', 'routes'];

                for (var i = 0; i < names.length; i++) {
                    var name = names[i];

                    if (filter[name]) {
                        var value = Array.isArray(filter[name])
                            ? filter[name].map(function (x) {
                                return x.name;
                            }).join(separator)
                            : filter[name];

                        s[OmnitureDictionaryHelper.props.filter[name]] = value;
                    }
                }

                s.events = OmnitureDictionaryHelper.events.filter.generateTable;

                s.t();
            },
            trackResetSelections() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.filter.resetSection;

                s.t();
            },
            trackPin(table) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.tablePinName] = table;
                s.events = OmnitureDictionaryHelper.events.tablePin;

                s.t();
            },
            trackTableAction(action) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.tableSelectionAction] = action;
                s.events = OmnitureDictionaryHelper.events.displayView;

                s.t();
            },
            trackPhase(phase) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                switch (phase) {
                    case 1:
                        s.events = OmnitureDictionaryHelper.events.phases.I;
                        break;
                    case 2:
                        s.events = OmnitureDictionaryHelper.events.phases.II;
                        break;
                    case 3:
                        s.events = OmnitureDictionaryHelper.events.phases.III;
                        break;
                    case 4:
                        s.events = OmnitureDictionaryHelper.events.phases.NDABLA;
                        break;
                }

                s.t();
            },
            trackDownload(type) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                switch (type) {
                    case 'dataset':
                        s.events = OmnitureDictionaryHelper.events.exports.downloadDataset;
                        break;
                    case 'table':
                        s.events = OmnitureDictionaryHelper.events.exports.exportTable;
                        break;
                }

                s.t();
            },
            trackAskTheAnalyst() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.askTheAnalyst;

                s.t();
            },
            trackSaveSelection() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.filter.saveSelection;

                s.t();
            },
            trackFAQ() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.FAQ;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.faq;

                s.t();
            },
            trackMyAccount() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.MyAccount;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.account.myAccount;

                s.t();
            },
            trackRegulatory(name) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.filter.regulatory] = name;
                s.events = OmnitureDictionaryHelper.events.filter.regulatory;

                s.t();
            },
            trackContactClientService() {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s.events = OmnitureDictionaryHelper.events.contactClientService;

                s.t();
            },
            export: {
                trackMain: (exportFileName) => trackExport(context, OmnitureDictionaryHelper.pages.main, exportFileName, AuthenticationService, OmnitureDictionaryHelper),
                trackChart: (exportFileName) => trackExport(context, OmnitureDictionaryHelper.pages.chart, exportFileName, AuthenticationService, OmnitureDictionaryHelper),
                trackGanttChart: (exportFileName) => trackExport(context, OmnitureDictionaryHelper.pages.ganttChart, exportFileName, AuthenticationService, OmnitureDictionaryHelper)
            },
            shareSearch(name) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.mySearches;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.shareSearchName] = name;
                s.events = OmnitureDictionaryHelper.events.shareSearch;

                s.t();
            },
            topHeaderBar: {
                trackExitToDMHealthCare() {
                    trackExitLink(context, 'DM Healthcare', AuthenticationService, OmnitureDictionaryHelper);
                },
                trackExitToBiomedtracker() {
                    trackExitLink(context, 'Biomedtracker', AuthenticationService, OmnitureDictionaryHelper);
                },
            },
            trackDashboardTabName(tabName) {
                s.clearVars();

                s.pageName = OmnitureDictionaryHelper.pages.main;

                trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

                s[OmnitureDictionaryHelper.props.dashboardTabName] = tabName;
                s.events = OmnitureDictionaryHelper.events.dashboardTab;

                s.t();
            },
            exitToBiomedtracker: {
                trackFromMainTable: () => trackExitToBiomedtracker(context, OmnitureDictionaryHelper.pages.main, 'Explore', AuthenticationService, OmnitureDictionaryHelper),
                trackFromTimelineView: () => trackExitToBiomedtracker(context, OmnitureDictionaryHelper.pages.ganttChart, 'Timeline View', AuthenticationService, OmnitureDictionaryHelper),
                trackFromDrugLevelPopup: () => trackExitToBiomedtracker(context, OmnitureDictionaryHelper.pages.main, 'Drug Level', AuthenticationService, OmnitureDictionaryHelper),
            }
        }
    }

    function trackLogInOut(AuthenticationService, event, pageName, OmnitureDictionaryHelper) {
        s.clearVars();
        s.pageName = pageName;
        
        trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);
        trackFederatedData(AuthenticationService, OmnitureDictionaryHelper);

        s.events = event;

        s.t();
    }

    function trackFederatedData(AuthenticationService, OmnitureDictionaryHelper) {
        const user = AuthenticationService.getCurrentUser();

        if (!user.isFederated) { 
            return;
        }

        const userProperties = OmnitureDictionaryHelper.props.user;
        
        s[userProperties.federatedLocation] = user.federatedData_clientLocation;
        s[userProperties.federatedDepartment] = user.federatedData_clientDepartment; 
        s[userProperties.federatedJobRole] = user.federatedData_clientJobRole;
        s[userProperties.federatedAttribute1] = user.federatedData_clientAttribute1;
        s[userProperties.federatedAttribute2] = user.federatedData_clientAttribute2;        
    }

    function trackExitToBiomedtracker(context, page, source, AuthenticationService, OmnitureDictionaryHelper) {
        s.clearVars();

        s.pageName = page;

        trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

        s[context.omnitureDictionaryHelper.props.exitToBiomedtrackerSourceName] = source;
        s.events = context.omnitureDictionaryHelper.events.exitToBiomedtracker;

        s.t();
    }

    function trackExitLink(context, target, AuthenticationService, OmnitureDictionaryHelper) {
        s.clearVars();

        s.pageName = context.omnitureDictionaryHelper.pages.main;

        trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

        s[context.omnitureDictionaryHelper.props.headerExitLinkName] = target;
        s.events = context.omnitureDictionaryHelper.events.headerExitLink;

        s.t();
    }

    function trackExport(context, pageName, exportFileName, AuthenticationService, OmnitureDictionaryHelper) {
        s.clearVars();

        s.pageName = pageName;

        trackUserInfo(AuthenticationService, OmnitureDictionaryHelper);

        s[context.omnitureDictionaryHelper.props.exportName] = exportFileName;
        s.events = context.omnitureDictionaryHelper.events.exportCount;

        s.t();
    }

    function trackUserInfo(AuthenticationService, OmnitureDictionaryHelper) {
        const userProperties = OmnitureDictionaryHelper.props.user;
        const user = AuthenticationService.getCurrentUser();
        
        s[userProperties.parentServiceAccountName] = user.parentServiceAccountName;
        s[userProperties.preferredUsername] = user.username;
        s[userProperties.userEntitlementType] = user.userEntitlementType; 
        s[userProperties.salesforceServiceId] = user.salesforceServiceId;
        s[userProperties.salesforceId] = user.salesforceId;
    }


    
})();
